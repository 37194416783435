<template>
    <div>
        <vendorHeader />
        <div class="m-2 pa-2">
            <tabs-comp :tabs="tabs" />
            <v-row>
              <v-col cols="12" md="12" sm="12" class="text-end">
                <b-button
                    class="btn-sm btn "
                    style="margin-top:11px;width:150px;background:transparent;border:none"
                    id="multiply-button" 
                    @click="requestpayment()"
                  >
                  <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{ lang.request_payment }}</div>
                  
                  </b-button>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-simple-table striped hover style="width:100%;">
                        <thead>
                            <tr>
                                <th class="text-center">{{ lang.payment_date }}</th>
                                <th class="text-center">{{ lang.start_date }}</th>
                                <th class="text-center">{{lang.end_date}}</th>
                                <th class="text-center">{{lang.amounttotal}}</th>
                                <th class="text-center">نسبة المحل</th>
                                <th class="text-center">المبلغ المطلوب</th>
                                <th class="text-center">الحالة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in myrequests" :key="index">
                                <td class="text-center">{{String(item.reqdate).substr(0,10)}}</td>
                                <td class="text-center">{{String(item.sdate).substr(0,10)}}</td>
                                <td class="text-center">{{String(item.edate).substr(0,10)}}</td>
                                <td class="text-center">{{item.total}}</td>
                                <td class="text-center">{{item.percentage}}</td>
                                <td class="text-center">{{item.paidtotal}}</td>
                                <td class="text-center" v-html="getPaymentStatus(item.status)">{{item.status}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import vendorHeader from '@/components/vendor-header.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default({
    components: {vendorHeader,Footer,TabsComp},
    data() {
        return {
            myrequests:[
              
            ]
        }
    },
    created() {
      this.getPayment();
    },
    methods: {
      getPaymentStatus(s){
        if(s == 1) return "تحت الاجراء";
        if(s == 2) return "تم السداد";
      },
      requestpayment(){
          const post = new FormData();
          post.append('type', 'requestVpayment');
          post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[a]',1);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              const res = response.data;
              // // console.log(res);
              this.getPayment();
          });
      },
      getPayment(){
        const post = new FormData();
          post.append('type', 'getVpayment');
          post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[a]',1);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              const res = response.data;
              // // console.log(res);
              this.myrequests = res.results.data;
          });
      }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }, 
        tabs: function(){
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    }
})
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
</style>